/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');

* {
  font-size: 1rem;
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
}


.table-responsive .table {
  th {
    border-top: none;
    border-bottom: none;
    vertical-align: middle;
  }

  tbody {
    tr:first-child td {
      border-top: none;
    }

    td {
      vertical-align: middle;

      p {
        display: inline-block;
        margin-left: 30px;
      }
    }
  }
}

@import 'swiper/css/bundle';

.tm {
  --background: rgb(69 69 69 / 79%) !important;

}

button {
  margin: 20px;
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  // box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
  //   7px 7px 20px 0px rgba(0, 0, 0, .1),
  //   4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

.btn-3 {
  background: rgb(0, 172, 238);
  background: linear-gradient(0deg, rgba(0, 172, 238, 1) 0%, rgba(2, 126, 251, 1) 100%);
  width: 100%;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;

}

.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}

.btn-3:before {
  height: 0%;
  width: 2px;
}

.btn-3:after {
  width: 0%;
  height: 2px;
}

.btn-3:hover {
  background: transparent;
  box-shadow: none;
}

.btn-3:hover:before {
  height: 100%;
}

.btn-3:hover:after {
  width: 100%;
}

.btn-3 span:hover {
  color: rgba(2, 126, 251, 1);
}

.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}

.btn-3 span:before {
  width: 2px;
  height: 0%;
}

.btn-3 span:after {
  width: 0%;
  height: 2px;
}

.btn-3 span:hover:before {
  height: 100%;
}

.btn-3 span:hover:after {
  width: 100%;
}


.chipborderonly {
  padding: 8px 10px;
  // border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  //box-shadow: 0 2px 5px rgba(0,0,0,.25);
  // margin: 0 10px;

  cursor: pointer;
  padding: 3px
}


.chip {
  padding: 8px 10px;
  display: block;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  padding: 3px;
  width: fit-content;
  text-align: center;
}

.chip.yellow {
  background: #d0ca06;
  color: white;
  padding: 3px
}

.chip.blue {
  background: #4c92c1;
  color: white;
  padding: 3px
}

.chip.green {
  background: #1ABC9C;
  color: white;
  padding: 3px
}

.chip.orange {
  background: #d46a0d;
  color: white;
  padding: 3px
}

.chip.deepgreen {
  background: #19880b;
  color: white;
  padding: 3px
}

.chip.deepblue {
  background: #5870e7;
  color: white;
  padding: 3px
}

.chip.skyblue {
  background: #34b5dd;
  color: white;
  padding: 3px
}

.chip.oceangreen {
  background: #34b5dd;
  color: white;
  padding: 3px
}

.chip.red {
  background: #dd4834;
  color: white;
  padding: 3px
}

.chip.grey {
  background: #acaaaa;
  color: white;
  padding: 3px
}

.chip-avatar {
  border-radius: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.chip-avatar img {
  height: 25px;
  width: 25px;
  border-radius: 50px;
}

.chip-avatar label {
  margin-left: 5px;
  font-size: 12px;
}

.tst-dtls {
  font-weight: 700;
  margin: 3px 0;
  border-radius: 10px;
  border-radius: 10px;
  background: #fff;
  width: 100%;
  padding-top: 2px;
}

.tg-wrap {
  // margin-top: 2rem;
  border-radius: 4px;
  width: 100%;
  padding: 5px 12px;
  padding-top: 2px;
  // height: 220px;
  border: 1px solid #cccccc;
  height: 150px;
  // box-shadow: 0px 0px 11px 2px #d2c9c9;
}


.normal-wrapper {
  // overflow-x: scroll;
  // overflow-y: hidden;
  // white-space: nowrap;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin: 10px;

  .card {
    display: inline-block;
  }

  .card-basic,
  .card-premium,
  .card-standard,
  .card-economy {
    display: inline-block;
    margin: 0 2rem 1rem 0;
    padding: 0 0 0.5rem 0;
    width: 25%;
    background: #fff;
    color: #444;
    text-align: center;
    box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
    overflow: hidden;
    transition: all 0.1ms ease-in-out;
    margin: 10px;
    height: 100px;
  }

  .card-basic:hover,
  .card-premium:hover,
  .card-standard:hover,
  .card-economy:hover {
    transform: scale(1.02);
  }

  .card-header {
    height: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    padding: 1rem 0;
    color: #fff;
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
  }

  .header-basic,
  .btn-basic,
  .card-basic {
    background: linear-gradient(135deg, #1A5276, #5499C7);
  }

  .normal-wrapper .header-basic,
  .normal-wrapper .btn-basic,
  .normal-wrapper .card-white {
    background: linear-gradient(135deg, #ffffff, #ffffff);
  }

  .image-fill {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header-standard,
  .btn-standard,
  .card-standard {
    background: linear-gradient(135deg, #117864, #45B39D);
  }

  .header-premium,
  .btn-premium,
  .card-premium {
    background: linear-gradient(135deg, #9C640C, #F5B041);
  }

  .header-economy,
  .btn-economy,
  .card-economy {
    background: linear-gradient(135deg, #633974, #A569BD);
  }

  .card-body {
    padding: 0.5rem 0;
  }

  .card-body h2 {
    font-size: 2rem;
    font-weight: 700;
  }

  .card-element-container {
    color: #444;
    list-style: none;
  }

  .btn {
    margin: 0.5rem 0;
    padding: 0.7rem 1rem;
    outline: none;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: all 0.1ms ease-in-out;
  }

  .btn:hover {
    transform: scale(0.95);
  }

  .btn:active {
    transform: scale(1);
  }

  .card-element-hidden {
    display: none;
  }

  .h1 {
    font-size: xx-large
  }

  .top {
    height: 40px;
    text-align: start;
    margin-left: 5px;
  }


}

.wrapper-progressbar {
  width: 90%;
  margin-left: 10px;



  .wrap p {
    // position: absolute;
    font-size: .8em;
    top: 50%;
    left: 20px;
    margin: 0 auto;
    color: #000;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    letter-spacing: 1px;
  }

  .wrap {
    position: relative;
    width: 100%;
    height: 30px;
    margin: 25px auto;
    padding: 0;
    // background: #444;
    // border-radius: 5px;
    -webkit-transition: .5s ease;
    -moz-transition: .5s ease;
    -o-transition: .5s ease;
    transition: .5s .15s ease;
  }

  .bar {
    height: 30px;
    // border-radius: 5px;
    transition: .25s ease;
  }

  .wrap:hover .bar {
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
  }

  .wrap:hover {
    width: 600px;
    background: #555;
  }

  .new {
    background: #AAB7B8;
    -webkit-animation: html 2.5s;
    -moz-animation: html 2.5s;
    -o-animation: html 2.5s;
    animation: html 2.5s;
  }

  .wip {
    background: #58D68D;
    -webkit-animation: css 2s;
    -moz-animation: css 2s;
    -o-animation: css 2s;
    animation: css 2s;
  }

  .completed {
    background: #1ABC9C;
    -webkit-animation: jquery 2.1s;
    -moz-animation: jquery 2.1s;
    -o-animation: jquery 2.1s;
    animation: jquery 2.1s;

  }

  .cancelled {
    background: #E67E22;
    -webkit-animation: js 1.8s;
    -moz-animation: js 1.8s;
    -o-animation: js 1.8s;
    animation: js 1.8s;
  }

  .paymentdue {
    background: #ffc107;
    -webkit-animation: php 2.9s;
    -moz-animation: php 2.9s;
    -o-animation: php 2.9s;
    animation: php 2.9s;
  }


  .violet {
    background: #AF7AC5;
    -webkit-animation: php 2.9s;
    -moz-animation: php 2.9s;
    -o-animation: php 2.9s;
    animation: php 2.9s;
  }

  .wrap span {
    display: block;
    float: right;
    padding-right: 15px;
    color: #222;
    line-height: 30px;
    -webkit-animation: span 2s 1.7s forwards;
    -moz-animation: span 2s 1.7s forwards;
    -o-animation: span 2s 1.7s forwards;
    animation: span 2s 1.7s forwards;
    opacity: 0;
  }

  .desc {
    display: none;
    box-sizing: border-box;
    padding: 10px;
    width: 600px;
    background: #333;
    border-radius: 10px;
    margin: 0 auto;
  }

  .desc p {
    color: white;
    margin: 0 auto;
  }

  /* Keyframes
-------------------- */


  /* new
--------- */
  @-webkit-keyframes new {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-moz-keyframes new {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-o-keyframes new {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes new {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  /* wip
--------- */
  @-webkit-keyframes wip {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-moz-keyframes wip {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-o-keyframes wip {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes wip {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  /* cancelled
--------- */
  @-webkit-keyframes cancelled {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-moz-keyframes cancelled {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-o-keyframes cancelled {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes cancelled {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  /* completed
--------- */
  @-webkit-keyframes completed {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-moz-keyframes completed {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-o-keyframes completed {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes completed {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  /* paymentdue
--------- */
  @-webkit-keyframes paymentdue {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-moz-keyframes paymentdue {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-o-keyframes paymentdue {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes paymentdue {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  /* violet
--------- */
  @-webkit-keyframes violet {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-moz-keyframes violet {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-o-keyframes violet {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes violet {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  @-webkit-keyframes span {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-moz-keyframes span {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-o-keyframes span {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes span {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.blank-wrapper {
  // overflow-x: scroll;
  // overflow-y: hidden;
  // white-space: nowrap;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin: 10px;

  .card {
    display: inline-block;
  }

  .card-basic,
  .card-premium,
  .card-standard,
  .card-economy {
    display: inline-block;
    margin: 0 2rem 1rem 0;
    padding: 0 0 0.5rem 0;
    width: 25%;
    background: #fff;
    color: #444;
    text-align: center;
    box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
    overflow: hidden;
    transition: all 0.1ms ease-in-out;
    margin: 10px;
    height: 100px;
  }

  .card-basic:hover,
  .card-premium:hover,
  .card-standard:hover,
  .card-economy:hover {
    transform: scale(1.02);
  }

  .card-header {
    height: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    padding: 1rem 0;
    color: #fff;
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
  }

  .header-basic,
  .btn-basic,
  .card-basic {
    background: linear-gradient(135deg, #ffffff, #eeeaea);
    // border: 0.8px solid;
  }

  .header-standard,
  .btn-standard,
  .card-standard {
    background: linear-gradient(135deg, #ffffff, #eeeaea);
    // border: 0.8px solid;
  }

  .header-premium,
  .btn-premium,
  .card-premium {
    background: linear-gradient(135deg, #ffffff, #eeeaea);
    // border: 0.8px solid;
  }

  .header-economy,
  .btn-economy,
  .card-economy {
    background: linear-gradient(135deg, #ffffff, #eeeaea);
    // border: 0.8px solid;
  }

  .card-body {
    padding: 0.5rem 0;
  }

  .card-body h2 {
    font-size: 2rem;
    font-weight: 700;
  }

  .card-element-container {
    color: #444;
    list-style: none;
  }

  .btn {
    margin: 0.5rem 0;
    padding: 0.7rem 1rem;
    outline: none;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: all 0.1ms ease-in-out;
  }

  .btn:hover {
    transform: scale(0.95);
  }

  .btn:active {
    transform: scale(1);
  }

  .card-element-hidden {
    display: none;
  }

  .h1 {
    font-size: xx-large
  }

  .top {
    height: 40px;
    text-align: start;
    margin-left: 5px;
  }

  .figure {
    font-size: 18px;
    color: #1F618D;
    margin-top: 10px;
    text-align: end;
    margin-right: 5px;
  }

  .heading {
    color: #1F618D;
    font-size: 10px;
  }

}


*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Josefin Sans", sans-serif;
  color: #384047;
  text-transform: uppercase;
}

form {
  max-width: 300px;
  margin: 10px auto;
  padding: 10px 20px;
  background: #f4f7f8;
  border-radius: 8px;
}

h1 {
  margin: 0 0 30px 0;
  text-align: center;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 12px;
  height: 36px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  background-color: #e8eeef;
  color: #000;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  margin-bottom: 10px;
}



input[type="radio"],
input[type="checkbox"] {
  margin: 0 4px 8px 0;
}

select {
  padding: 6px;
  height: 32px;
  border-radius: 2px;
}

button {
  padding: 19px 39px 18px 39px;
  color: #FFF;
  background-color: #3caadb;
  font-size: 14px;
  text-align: center;
  font-style: normal;
  //border-radius: 5px;
  width: 100%;
  height: 25px;
  border: 1px solid #3caadb;
  border-width: 1px 1px 3px;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  margin-bottom: 10px;
  align-items: center;
}

fieldset {
  margin-bottom: 30px;
  border: none;
}

legend {
  font-size: 1em;
  margin-bottom: 10px;
  text-align: right;
  /* border-bottom: 1px solid; */
  border-bottom: 1px solid #e0e0e0;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
}

label.light {
  font-weight: 300;
  display: inline;
}

.number {
  background-color: #5fcf80;
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}

@media screen and (min-width: 480px) {

  form {
    max-width: 480px;
  }

}

fieldset {
  margin-top: 10px;
}

.switcher {
  display: inline-block;
  height: 40px;
  margin-top: 3px;
  padding: 4px;
  background: #fff;
  border-radius: 2px;
  width: 200px;
  border: solid 1px #ddd;
  position: relative;
}

.switcher__input {
  display: none;
}

.switcher__label {
  float: left;
  width: 50%;
  font-size: 12px;
  line-height: 40px;
  color: #000;
  text-align: center;
  cursor: pointer;
  position: inherit;
  z-index: 10;
  transition: color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  will-change: transform;
}

.switcher__toggle {
  position: absolute;
  float: left;
  height: 30px;
  width: 50%;
  font-size: 12px;
  line-height: 40px;
  cursor: pointer;
  background-color: #3366cc;
  left: 5px;
  top: 4px;
  transition: left 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);
  will-change: transform;
}

.switcher__input:checked+.switcher__label {
  color: #fff;
}

.switcher__input--yang:checked~.switcher__toggle {
  left: 100px;
}

.header {
  text-transform: uppercase;
  margin: 10px;
  font-family: "Josefin Sans", sans-serif;
  border-bottom: 1px solid #e0e0e0;

}

.search {
  width: 98%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #00B4CC;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
}

.searchTerm:focus {
  color: #00B4CC;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #00B4CC;
  background: #00B4CC;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/

.tabletext {
  font-size: smaller;
}


.hori-timeline .events {
  border-top: 3px solid #e9ecef;
}

.hori-timeline .events .event-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 70px;
  margin-right: 0;
}

.hori-timeline .events .event-list:before {
  content: "";
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 0;
}

.hori-timeline .events .event-list .event-date {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  width: 205px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 2px 4px;
}

@media (min-width: 1140px) {
  .hori-timeline .events .event-list {
    display: inline-block;
    width: 24%;
    padding-top: 45px;
  }

  .hori-timeline .events .event-list .event-date {
    top: -12px;
  }
}

.bg-soft-primary {
  background-color: rgb(0 64 127 / 62%) !important;
}

.bg-soft-success {
  background-color: rgba(71, 189, 154, .3) !important;
}

.bg-soft-danger {
  background-color: rgba(231, 76, 94, .3) !important;
}

.bg-soft-warning {
  background-color: rgba(249, 213, 112, .3) !important;
}

.card {
  border: none;
  margin-bottom: 24px;
  -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
  box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
}



chip {
  padding: 8px 10px;
  display: block;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  padding: 3px;
  width: -moz-fit-content;
  /* width: fit-content; */
  text-align: center;
  height: 30px;
  border-radius: 5px;
  margin-right: 40px;
}

.chip.yellow {
  background: #d0ca06;
  color: white;
  padding: 3px
}

.chip.blue {
  background: #4c92c1;
  color: white;
  padding: 3px
}

.chip.green {
  background: #1ABC9C;
  color: white;
  padding: 3px
}

.chip.orange {
  background: #d46a0d;
  color: white;
  padding: 3px
}

.chip.deepgreen {
  background: #19880b;
  color: white;
  padding: 3px
}

.chip.deepblue {
  background: #5870e7;
  color: white;
  padding: 3px
}

.chip.skyblue {
  background: #34b5dd;
  color: white;
  padding: 3px
}

.chip.oceangreen {
  background: #34b5dd;
  color: white;
  padding: 3px
}

.chip.red {
  background: #dd4834;
  color: white;
  padding: 3px
}

.chip.grey {
  background: #acaaaa;
  color: white;
  padding: 3px
}



.product-grid {
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  overflow: hidden;
  position: relative;
  border: 4px solid #fff;
  margin-top: 184px;
}

.product-grid:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.product-grid:hover:before {
  background-color: rgba(0, 0, 0, 0.05);
}

.product-grid .product-image {
  overflow: hidden;
  position: relative;
}

.product-grid .product-image a {
  display: block;
}

.product-grid .product-image img {
  width: 100%;
  height: auto;
}

.product-discount-label {
  color: #fff;
  background-color: #000;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 8px 12px;
  border-radius: 5px;
  position: absolute;
  left: 15px;
  top: 15px;
}

.product-grid .social {
  width: 100%;
  padding: 30px 0;
  margin: 0;
  list-style: none;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
}

.product-grid .social li {
  display: inline-block;
  opacity: 0;
  transform: translateY(300%);
  transition: all 0.5s ease 0s;
}

.product-grid .social li:nth-child(2) {
  transition-delay: 0.05s;
}

.product-grid .social li:nth-child(3) {
  transition-delay: 0.13s;
}

.product-grid .social li:nth-child(4) {
  transition-delay: 0.16s;
}

.product-grid:hover .social li {
  opacity: 1;
  transform: translateY(0);
}

.product-grid .social li a {
  color: #000;
  background-color: #fff;
  line-height: 50px;
  height: 50px;
  width: 50px;
  margin: 0 2px 10px;
  border-radius: 50%;
  display: block;
  position: relative;
  z-index: 2;
  transition: all 0.3s;
}

.product-grid .social li a:hover {
  color: #fff;
  background-color: orange;
}

.product-grid .product-content {
  padding: 1px;
  position: relative;
  z-index: 2;
  background-color: white;
}

.product-grid .title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.product-grid .title a {
  color: #000;
}

.product-grid .title a:hover {
  color: #fa8231;
}

.product-grid .price {
  color: #fa8231;
  font-size: 18px;
  font-weight: 600;
}

.product-grid .price span {
  color: #777;
  margin: 0 0 0 5px;
  font-weight: 300;
  text-decoration: line-through;
  display: inline-block;
}

@media only screen and (max-width:990px) {
  .product-grid {
    margin-bottom: 30px;
  }
}

.pocial {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: red;
  font-family: verdana;
  padding: 5px;
  border-radius: 5px;
  animation: new .5s linear infinite;
  margin-top: -50px;
}

.pocial a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

@keyframes new {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scaleX(.95);
  }

  100% {
    transform: scaleX(1);
  }
}


.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-grid {
  flex: 1 1 calc(50% - 10px); /* Adjust based on the number of columns */
  margin: 5px;
  box-sizing: border-box;
  /* Box shadow properties */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Horizontal offset, vertical offset, blur radius, and color */
 /* Optional: rounded corners for a softer shadow effect */
}

.product-image {
  width: 100%;
  height: 200px; /* Fixed height to ensure consistent image size */
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0; /* Optional: Adds a background color to fill the space around the image */
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the entire image is shown */
  object-position: center; /* Center the image */
  display: block;
}

.product-content {
  margin-top: 10px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 16px;
  margin: 0;
}

.product-content button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.product-content button:hover {
  background-color: #0056b3;
}
